export const copywrite = {
  main_title: "Konservasi Daerah Aliran Sungai Dayang",
  description: [
    //eslint-disable-next-line
    "Daerah Aliran Sungai (DAS) merupakan suatu kawasan daratan yang dibatasi oleh pembatas lanskap \
    yang berfungsi untuk menerima, mengumpulkan air hujan, sedimen, dan unsur hara serta mengalirkannya \
    menuju ke laut atau danau. Daerah Aliran Sungai memiliki peranan penting dalam sumber daya hidrologi, \
    terutama dalam mengalirkan air, menjaga kondisi lanskap ketika puncak hujan, menjaga kualitas \
    air dan mengurangi pembuangan massa permukaan tanah. Berdasarkan fungsinya, daerah aliran sungai \
    terbagi menjadi 3 bagian, yaitu 1) Daerah aliran sungai hulu; 2) Daerah aliran sungai tengah; \
    3) Daerah aliran sungai hilir. Salah satu sungai di Kabupaten Bengkalis yang menjadi daerah aliran \
    sungai tengah adalah Sungai Dayang.",
    //eslint-disable-next-line
    "Sungai Dayang yang merupakan Sub Daerah Aliran Sungai Siak mengalir menuju muaranya di Selat \
    Bangkalis. Letak Sungai Dayang yang ada di tengah menyebabkan sungai ini sangat vital bagi \
    kehidupan masyarakat yang ada di sekitarnya, terutama dalam aspek sosial dan ekonomi. Akan \
    tetapi seperti yang dialami banyak daerah aliran sungai lainnya, kondisi Sungai Dayang juga \
    dipengaruhi oleh tata guna lahan di daratannya. Alih fungsi lahan dan berkurangnya area aliran \
    air akan menurunkan kondisi perairan sungai dan proses-proses hidrologis dalam wilayah DAS.",
    //eslint-disable-next-line
    "Berdasarkan pengukuran yang dilakukan oleh PT Kilang Pertamina Internasional Refinery Unit II \
    Produksi Sungai Pakning pada Tahun 2018 dan 2019 melalui titik-titik survey di sepanjang Sungai \
    Dayang, terutama di Dusun Beringin, Desa Lubuk Muda, Kecamatan Siak Kecil, Kabupaten Bengkalis, \
    terdapat beberapa indikator yang menunjukkan degradasi kualitas air Sungai Dayang seperti, pH, \
    warna, Chemical Oxygen Demand (COD), berasa, dan berbau. Hal tersebut tidak sesuai dengan standar \
    Peraturan Menteri Kesehatan Republik Indonesia No. 32 Tahun 2017.",
    //eslint-disable-next-line
    "Mengingat pentingnya peran Sungai Dayang bagi masyarakat sekitar dan fungsinya dalam ekosistem, \
    PT Kilang Pertamina Internasional Refinery Unit II Produksi Sungai Pakning bersama masyarakat Desa \
    Lubuk Muda mengembangkan Program Konservasi Daerah Aliran Sungai Dayang guna mempertahankan \
    kelestarian dari Sungai Dayang dan memperbaiki kondisi di sekitar DAS Dayang",
  ],
  flora_table_title: "Keanekaragaman Hayati Konservasi Daerah Aliran",
  bird_table_title: "Jumlah spesies Burung Konservasi Daerah Aliran",
  mammals_table_title: "Jumlah spesies Mamalia Konservasi Daerah Aliran",
  herpetofauna_table_title:
    "Jumlah spesies Herpetofauna Konservasi Daerah Aliran",
  dragonfly_table_title: "Jumlah spesies Capung Konservasi Daerah Aliran",
  butterfly_table_title: "Jumlah spesies Kupu-kupu Konservasi Daerah Aliran",
};

export const flora = [
  {
    No: "1",
    spesies: "Adenium obesum",
    nama_lokal: "Adenium",
    y2023: 5,
    y2024: 0,
  },
  {
    No: "2",
    spesies: "Adonidia merrillii",
    nama_lokal: "Palem putri",
    y2023: 0,
    y2024: 4,
  },
  {
    No: "3",
    spesies: "Agave sp.",
    nama_lokal: "Agave",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "4",
    spesies: "Ageratum conyzoides",
    nama_lokal: "Bandotan",
    y2023: 50,
    y2024: 0,
  },
  {
    No: "5",
    spesies: "Aglaonema sp.",
    nama_lokal: "Aglonema",
    y2023: 2,
    y2024: 0,
  },
  {
    No: "6",
    spesies: "Allamanda cathartica",
    nama_lokal: "Alamanda",
    y2023: 1,
    y2024: 4,
  },
  {
    No: "7",
    spesies: "Alocasia zebrina",
    nama_lokal: "Alokasia",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "8",
    spesies: "Alpinia galanga",
    nama_lokal: "Lengkuas",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "9",
    spesies: "Annona muricata",
    nama_lokal: "Sirsak",
    y2023: 0,
    y2024: 14,
  },
  {
    No: "10",
    spesies: "Archidendron pauciflorum",
    nama_lokal: "Jengkol",
    y2023: 14,
    y2024: 24,
  },
  {
    No: "11",
    spesies: "Areca catechu",
    nama_lokal: "Pinang",
    y2023: 7,
    y2024: 8,
  },
  {
    No: "12",
    spesies: "Artocarpus altilis",
    nama_lokal: "Sukun",
    y2023: 3,
    y2024: 0,
  },
  {
    No: "13",
    spesies: "Artocarpus heterophyllus",
    nama_lokal: "Nangka",
    y2023: 9,
    y2024: 10,
  },
  {
    No: "14",
    spesies: "Artocarpus integer",
    nama_lokal: "Cempedak",
    y2023: 1,
    y2024: 10,
  },
  {
    No: "15",
    spesies: "Asplenium nidus",
    nama_lokal: "Paku sarang burung",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "16",
    spesies: "Asystasia gangetica",
    nama_lokal: "Rumput israel",
    y2023: 0,
    y2024: 4,
  },
  {
    No: "17",
    spesies: "Baccaurea macrocarpa",
    nama_lokal: "Tampui",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "18",
    spesies: "Bambusa sp.",
    nama_lokal: "Bambu",
    y2023: 4,
    y2024: 0,
  },
  {
    No: "19",
    spesies: "Bambusa vulgaris",
    nama_lokal: "Bambu kuning",
    y2023: 2,
    y2024: 0,
  },
  {
    No: "20",
    spesies: "Bougainvillea glabra",
    nama_lokal: "Bougenvile",
    y2023: 12,
    y2024: 4,
  },
  {
    No: "21",
    spesies: "Caladium bicolor",
    nama_lokal: "Keladi",
    y2023: 3,
    y2024: 0,
  },
  {
    No: "22",
    spesies: "Calamus rotang",
    nama_lokal: "Rotan",
    y2023: 1,
    y2024: 12,
  },
  {
    No: "23",
    spesies: "Casalia cuviflora",
    nama_lokal: "Bintang jatuh",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "24",
    spesies: "Cerbera manghas",
    nama_lokal: "Bintaro",
    y2023: 9,
    y2024: 0,
  },
  {
    No: "25",
    spesies: "Citrus sp.",
    nama_lokal: "Jeruk",
    y2023: 11,
    y2024: 4,
  },
  {
    No: "26",
    spesies: "Cnidoscolus aconitifolius",
    nama_lokal: "Pepaya jepang",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "27",
    spesies: "Cocos nucifera",
    nama_lokal: "Kelapa",
    y2023: 8,
    y2024: 12,
  },
  {
    No: "28",
    spesies: "Codiaeum variegatum",
    nama_lokal: "Puring",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "29",
    spesies: "Coleus scutellarioides",
    nama_lokal: "Rumput miana",
    y2023: 0,
    y2024: 4,
  },
  {
    No: "30",
    spesies: "Cordyline sp.",
    nama_lokal: "Cordiline",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "31",
    spesies: "Cratoxylum arborescens",
    nama_lokal: "Geronggang",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "32",
    spesies: "Cupressus sempervirens",
    nama_lokal: "Cemara lilin",
    y2023: 2,
    y2024: 0,
  },
  {
    No: "33",
    spesies: "Curcuma longa",
    nama_lokal: "Kunyit",
    y2023: 4,
    y2024: 0,
  },
  {
    No: "34",
    spesies: "Curcuma zanthorrhiza",
    nama_lokal: "Temulawak",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "35",
    spesies: "Cycas rumphii",
    nama_lokal: "Mawar jambe",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "36",
    spesies: "Cymbopogon citratus",
    nama_lokal: "Serai",
    y2023: 3,
    y2024: 2,
  },
  {
    No: "37",
    spesies: "Dracaena fragrans",
    nama_lokal: "Sri gading",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "38",
    spesies: "Dracaena marginata",
    nama_lokal: "Drakaena",
    y2023: 4,
    y2024: 0,
  },
  {
    No: "39",
    spesies: "Dracaena sp.",
    nama_lokal: "Drakaena",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "40",
    spesies: "Durio zibethinus",
    nama_lokal: "Durian",
    y2023: 14,
    y2024: 44,
  },
  {
    No: "41",
    spesies: "Elaeis guineensis",
    nama_lokal: "Sawit",
    y2023: 4,
    y2024: 60,
  },
  {
    No: "42",
    spesies: "Euodia ridleyi",
    nama_lokal: "Brokoli hias",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "43",
    spesies: "Euphorbia lactea",
    nama_lokal: "Euphorbia green",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "44",
    spesies: "Euphorbia milii",
    nama_lokal: "Mahkota duri",
    y2023: 3,
    y2024: 2,
  },
  {
    No: "45",
    spesies: "Euphorbia pseudocactus",
    nama_lokal: "Euphorbia zig zag",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "46",
    spesies: "Euphorbia tithymaloides",
    nama_lokal: "Patah tulang",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "47",
    spesies: "Ficus sp.",
    nama_lokal: "Beringin",
    y2023: 2,
    y2024: 2,
  },
  {
    No: "48",
    spesies: "Flagellaria indica",
    nama_lokal: "Rotan tikus",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "49",
    spesies: "Garcinia mangostana",
    nama_lokal: "Manggis",
    y2023: 1,
    y2024: 2,
  },
  {
    No: "50",
    spesies: "Gomphandra capitulata",
    nama_lokal: "Mesiro",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "51",
    spesies: "Hanguana malayana",
    nama_lokal: "Bakung",
    y2023: 100,
    y2024: 0,
  },
  {
    No: "52",
    spesies: "Hevea brasiliensis",
    nama_lokal: "Karet",
    y2023: 33,
    y2024: 60,
  },
  {
    No: "53",
    spesies: "Hibiscus rosa-sinensis",
    nama_lokal: "Kembang sepatu",
    y2023: 3,
    y2024: 4,
  },
  {
    No: "54",
    spesies: "Homalomena sp.",
    nama_lokal: "Homalomena",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "55",
    spesies: "Impatiens balsamina",
    nama_lokal: "Pacar air",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "56",
    spesies: "Ixora sp.",
    nama_lokal: "Asoka",
    y2023: 10,
    y2024: 4,
  },
  {
    No: "57",
    spesies: "Jasminum sambac",
    nama_lokal: "Melati",
    y2023: 3,
    y2024: 0,
  },
  {
    No: "58",
    spesies: "Kaempferia galanga",
    nama_lokal: "Kencur",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "59",
    spesies: "-",
    nama_lokal: "Kaktus",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "60",
    spesies: "Lansium domesticum",
    nama_lokal: "Duku",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "61",
    spesies: "Lantana camara",
    nama_lokal: "Tahi ayam",
    y2023: 1,
    y2024: 2,
  },
  {
    No: "62",
    spesies: "leguminose",
    nama_lokal: "Leguminose",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "63",
    spesies: "Limahlania crenulata",
    nama_lokal: "Lelempang",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "64",
    spesies: "Lindernia rotundifolia",
    nama_lokal: "Rumput sawah",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "65",
    spesies: "Macaranga motleyana",
    nama_lokal: "Mahang",
    y2023: 7,
    y2024: 0,
  },
  {
    No: "66",
    spesies: "Macaranga sp.",
    nama_lokal: "Mahang",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "67",
    spesies: "Macaranga triloba",
    nama_lokal: "Mahang",
    y2023: 0,
    y2024: 4,
  },
  {
    No: "68",
    spesies: "Magnolia champaca",
    nama_lokal: "Cempaka",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "69",
    spesies: "Mangifera foetida",
    nama_lokal: "Pakel",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "70",
    spesies: "Mangifera indica",
    nama_lokal: "Mangga",
    y2023: 4,
    y2024: 8,
  },
  {
    No: "71",
    spesies: "Mangifera sp. 1",
    nama_lokal: "-",
    y2023: 3,
    y2024: 0,
  },
  {
    No: "72",
    spesies: "Manihot esculenta",
    nama_lokal: "Singkong",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "73",
    spesies: "Maniltoa grandiflora",
    nama_lokal: "Saputangan",
    y2023: 2,
    y2024: 0,
  },
  {
    No: "74",
    spesies: "Melaleuca leucadendra",
    nama_lokal: "Gelam",
    y2023: 0,
    y2024: 4,
  },
  {
    No: "75",
    spesies: "Melastoma malabathricum",
    nama_lokal: "Keduduk",
    y2023: 10,
    y2024: 4,
  },
  {
    No: "76",
    spesies: "Monstera deliciosa",
    nama_lokal: "Monstera",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "77",
    spesies: "Moringa oleifera",
    nama_lokal: "Kelor",
    y2023: 0,
    y2024: 4,
  },
  {
    No: "78",
    spesies: "Musa sp.",
    nama_lokal: "Pisang",
    y2023: 1,
    y2024: 8,
  },
  {
    No: "79",
    spesies: "Mussaenda frondosa",
    nama_lokal: "Kingkilaban",
    y2023: 0,
    y2024: 12,
  },
  {
    No: "80",
    spesies: "Mussaenda pubescens",
    nama_lokal: "Nusa indah",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "81",
    spesies: "Nephelium glabrum",
    nama_lokal: "Rendan",
    y2023: 0,
    y2024: 4,
  },
  {
    No: "82",
    spesies: "Nephelium lappaceum",
    nama_lokal: "Rambutan",
    y2023: 9,
    y2024: 18,
  },
  {
    No: "83",
    spesies: "Nymphaea sp.",
    nama_lokal: "Teratai",
    y2023: 0,
    y2024: 34,
  },
  {
    No: "84",
    spesies: "Pandanus helicopus",
    nama_lokal: "Pandan rawa",
    y2023: 54,
    y2024: 0,
  },
  {
    No: "85",
    spesies: "Pandanus tectorius",
    nama_lokal: "Pandan laut",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "86",
    spesies: "Parkia speciosa",
    nama_lokal: "Petai",
    y2023: 2,
    y2024: 2,
  },
  {
    No: "87",
    spesies: "Persea americana",
    nama_lokal: "Sirsak",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "88",
    spesies: "Pometia pinnata",
    nama_lokal: "Matoa",
    y2023: 1,
    y2024: 2,
  },
  {
    No: "89",
    spesies: "Psidium guajava",
    nama_lokal: "Jambu biji",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "90",
    spesies: "Rauvolfia serpentina",
    nama_lokal: "Pule pandak",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "91",
    spesies: "Ruellia tuberosa",
    nama_lokal: "Pletokan",
    y2023: 0,
    y2024: 6,
  },
  {
    No: "92",
    spesies: "Saccharum officinarum",
    nama_lokal: "Tebu",
    y2023: 2,
    y2024: 20,
  },
  {
    No: "93",
    spesies: "Sansevieria trifasciata",
    nama_lokal: "Lidah mertua",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "94",
    spesies: "Shorea pauciflora",
    nama_lokal: "Meranti",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "95",
    spesies: "Shorea sp.",
    nama_lokal: "",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "96",
    spesies: "Sonneratia alba",
    nama_lokal: "Pidada",
    y2023: 2,
    y2024: 4,
  },
  {
    No: "97",
    spesies: "Spermacoce rotundifolia",
    nama_lokal: "Rumput",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "98",
    spesies: "Stachytarpheta jamaicensis",
    nama_lokal: "Pecutan kuda",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "99",
    spesies: "Syzygium aqueum",
    nama_lokal: "Jambu air",
    y2023: 2,
    y2024: 4,
  },
  {
    No: "100",
    spesies: "Syzygium malaccense",
    nama_lokal: "Jambu bol",
    y2023: 4,
    y2024: 2,
  },
  {
    No: "101",
    spesies: "Syzygium myrtifolium",
    nama_lokal: "Pucuk merah",
    y2023: 43,
    y2024: 12,
  },
  {
    No: "102",
    spesies: "Syzygium sp. ",
    nama_lokal: "Syzygium sp.",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "103",
    spesies: "Tabernaemontana sp.",
    nama_lokal: "Mondokaki",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "104",
    spesies: "Acalypha siamensis",
    nama_lokal: "Teh-tehan",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "105",
    spesies: "Thuja occidentalis",
    nama_lokal: "Cemara kipas",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "106",
    spesies: "Torenia crustacea",
    nama_lokal: "Bunga wishbone",
    y2023: 0,
    y2024: 2,
  },
  {
    No: "107",
    spesies: "Tridax procumbens",
    nama_lokal: "Songgolangit",
    y2023: 1,
    y2024: 0,
  },
  {
    No: "108",
    spesies: "Vandellia diffusa",
    nama_lokal: "Rumput",
    y2023: 0,
    y2024: 4,
  },
  {
    No: "109",
    spesies: "Zingiber officinale",
    nama_lokal: "Jahe",
    y2023: 2,
    y2024: 0,
  },
];

export const birds = [
  {
    No: 1,
    spesies: "Lonchura maja",
    nama_lokal: "Bondol haji",
    y2023: 3,
    y2024: 3,
  },
  {
    No: 2,
    spesies: "Lonchura malacca",
    nama_lokal: "Bondol rawa",
    y2023: 12,
    y2024: 0,
  },
  {
    No: 3,
    spesies: "Lonchura striata",
    nama_lokal: "Bondol tunggir putih",
    y2023: 0,
    y2024: 2,
  },
  {
    No: 4,
    spesies: "Centropus bengalensis",
    nama_lokal: "Bubut alang-alang",
    y2023: 0,
    y2024: 1,
  },
  {
    No: 5,
    spesies: "Centropus sinensis",
    nama_lokal: "Bubut besar",
    y2023: 1,
    y2024: 0,
  },
  {
    No: 6,
    spesies: "Anthreptes malacensis",
    nama_lokal: "Burung madu kelapa",
    y2023: 4,
    y2024: 4,
  },
  {
    No: 7,
    spesies: "Cinnyris jugularis",
    nama_lokal: "Burung madu sriganti",
    y2023: 5,
    y2024: 5,
  },
  {
    No: 8,
    spesies: "Dicaeum trochileum",
    nama_lokal: "Cabai jawa",
    y2023: 1,
    y2024: 1,
  },
  {
    No: 9,
    spesies: "Dicaeum cruentatum",
    nama_lokal: "Cabai merah",
    y2023: 2,
    y2024: 2,
  },
  {
    No: 10,
    spesies: "Meiglyptes tukki",
    nama_lokal: "Caladi badok",
    y2023: 0,
    y2024: 3,
  },
  {
    No: 11,
    spesies: "Halcyon smyrnensis",
    nama_lokal: "Cekakak belukar",
    y2023: 3,
    y2024: 3,
  },
  {
    No: 12,
    spesies: "Orthotomus ruficeps",
    nama_lokal: "Cinenen kelabu",
    y2023: 2,
    y2024: 4,
  },
  {
    No: 13,
    spesies: "Pycnonotus aurigaster",
    nama_lokal: "Cucak kutilang",
    y2023: 3,
    y2024: 6,
  },
  {
    No: 14,
    spesies: "Turnix sylvatica",
    nama_lokal: "Gemak",
    y2023: 0,
    y2024: 1,
  },
  {
    No: 15,
    spesies: "Passer montanus",
    nama_lokal: "Gereja erasia",
    y2023: 26,
    y2024: 22,
  },
  {
    No: 16,
    spesies: "Acridotheres javanicus",
    nama_lokal: "Jalak kerbau",
    y2023: 32,
    y2024: 0,
  },
  {
    No: 17,
    spesies: "Amaurornis phoenicurus",
    nama_lokal: "Kareo padi",
    y2023: 3,
    y2024: 2,
  },
  {
    No: 18,
    spesies: "Artamus leucoryn",
    nama_lokal: "Kekep babi",
    y2023: 5,
    y2024: 0,
  },
  {
    No: 19,
    spesies: "Hirundo tahitica",
    nama_lokal: "Layang-layang Batu",
    y2023: 4,
    y2024: 5,
  },
  {
    No: 20,
    spesies: "Pycnonotus goiavier",
    nama_lokal: "Merbah cerucuk",
    y2023: 5,
    y2024: 5,
  },
  {
    No: 21,
    spesies: "Geopelia striata",
    nama_lokal: "Perkutut jawa",
    y2023: 6,
    y2024: 6,
  },
  {
    No: 22,
    spesies: "Treron vernans",
    nama_lokal: "Punai gading",
    y2023: 0,
    y2024: 1,
  },
  {
    No: 23,
    spesies: "Alcedo coerulescens",
    nama_lokal: "Raja udang biru",
    y2023: 1,
    y2024: 0,
  },
  {
    No: 24,
    spesies: "Eurystomus orientalis",
    nama_lokal: "Tiong lampu biasa",
    y2023: 2,
    y2024: 1,
  },
  {
    No: 25,
    spesies: "Collocalia esculenta",
    nama_lokal: "Walet sapi",
    y2023: 3,
    y2024: 3,
  },
  {
    No: 26,
    spesies: "Collocalia maxima",
    nama_lokal: "Walet sarang hitam",
    y2023: 9,
    y2024: 0,
  },
  {
    No: 27,
    spesies: "Aerodramus hirundinaceus",
    nama_lokal: "Walet sarang putih",
    y2023: 8,
    y2024: 8,
  },
];

export const mammals = [
  {
    No: 1,
    spesies: "Callosciurus notatus",
    nama_lokal: "Bajing kelapa",
    y2023: 4,
    y2024: 2,
  },
  {
    No: 2,
    spesies: "Macaca fascicularis",
    nama_lokal: "Monyet ekor panjang",
    y2023: 20,
    y2024: 24,
  },
  {
    No: 3,
    spesies: "Presbytis melalophos",
    nama_lokal: "Simpai hitam sumatera",
    y2023: 7,
    y2024: 1,
  },
  {
    No: 4,
    spesies: "Presbytis thomasi",
    nama_lokal: "Lutung kedih",
    y2023: 0,
    y2024: 10,
  },
  {
    No: 5,
    spesies: "Prionailurus bengalensis",
    nama_lokal: "Kucing hutan",
    y2023: 2,
    y2024: 1,
  },
  {
    No: 6,
    spesies: "Sus scrofa",
    nama_lokal: "Babi hutan",
    y2023: 2,
    y2024: 1,
  },
  {
    No: 7,
    spesies: "Trachypithecus cristatus",
    nama_lokal: "Lutung kelabu",
    y2023: 8,
    y2024: 9,
  },
];

export const herpetofauna = [
  {
    No: 1,
    spesies: "Crocodylus porosus",
    nama_lokal: "Buaya muara",
    y2023: 1,
    y2024: 0,
  },
  {
    No: 2,
    spesies: "Duttaphyrnus melanostictus",
    nama_lokal: "Bangkong kolong",
    y2023: 2,
    y2024: 1,
  },
  {
    No: 3,
    spesies: "Fejervarya cancrivora",
    nama_lokal: "Katak sawah",
    y2023: 2,
    y2024: 2,
  },
  {
    No: 4,
    spesies: "Hemidactylus frenatus",
    nama_lokal: "Cicak rumah",
    y2023: 2,
    y2024: 0,
  },
  {
    No: 5,
    spesies: "Hemidactylus platyurus",
    nama_lokal: "Cicak pohon",
    y2023: 1,
    y2024: 10,
  },
  {
    No: 6,
    spesies: "Hylarana baramica",
    nama_lokal: "Kongkang baram",
    y2023: 11,
    y2024: 4,
  },
  {
    No: 7,
    spesies: "Microhyla sp.",
    nama_lokal: "Percil",
    y2023: 0,
    y2024: 8,
  },
  {
    No: 8,
    spesies: "Ophiopohagus hannah",
    nama_lokal: "Ular kobra raja",
    y2023: 1,
    y2024: 0,
  },
  {
    No: 9,
    spesies: "Varanus salvator",
    nama_lokal: "Biawak",
    y2023: 1,
    y2024: 1,
  },
];

export const dragonfly = [
  {
    No: 1,
    spesies: "Copera vittata",
    nama_lokal: "-",
    y2024: 1,
  },
  {
    No: 2,
    spesies: "Neurothemis fluctuans",
    nama_lokal: "-",
    y2024: 26,
  },
  {
    No: 3,
    spesies: "Orthetrum chrysis",
    nama_lokal: "-",
    y2024: 3,
  },
  {
    No: 4,
    spesies: "Orthetrum sabina",
    nama_lokal: "-",
    y2024: 5,
  },
];

export const butterfly = [
  {
    No: 1,
    spesies: "Appias libythea",
    nama_lokal: "-",
    y2024: 2,
  },
  {
    No: 2,
    spesies: "Ypthima huebneri",
    nama_lokal: "-",
    y2024: 1,
  },
  {
    No: 3,
    spesies: "Mycalesis mineus",
    nama_lokal: "-",
    y2024: 1,
  },
  {
    No: 4,
    spesies: "Ancistroides sp.",
    nama_lokal: "-",
    y2024: 1,
  },
  {
    No: 5,
    spesies: "Neptis hylas",
    nama_lokal: "-",
    y2024: 2,
  },
  {
    No: 6,
    spesies: "Notocrypta paralysos",
    nama_lokal: "-",
    y2024: 1,
  },
  {
    No: 7,
    spesies: "Jamides sp.",
    nama_lokal: "-",
    y2024: 1,
  },
];

export const data_count = {
  flora: [
    { x: "2023", y: 501 },
    { x: "2024", y: 251 },
  ],
  birds: [
    { x: "2023", y: 140 },
    { x: "2024", y: 88 },
  ],
  mammals: [
    { x: "2023", y: 43 },
    { x: "2024", y: 48 },
  ],
  herpetofauna: [
    { x: "2023", y: 21 },
    { x: "2024", y: 26 },
  ],
  dragonfly: [{ x: "2024", y: 35 }],
  butterfly: [{ x: "2024", y: 9 }],
};

export const total_species = {
  flora: [{ x: "2023", y: 72, yOffset: -25 }],
  birds: [
    { x: "2022", y: 22, yOffset: -25 },
    { x: "2023", y: 22, yOffset: -25 },
  ],
};

export const hre_index = {
  flora: [
    { x: "2021", y: 2.31, yOffset: -25 },
    { x: "2022", y: 2.64, yOffset: -25 },
    { x: "2023", y: 2.67, yOffset: -25 },
  ],
  birds: [
    { x: "2023", h: 2.6, r: 4.25, e: 0.84, yOffset: 20 },
    { x: "2024", h: 3.52, r: 11.59, e: 0.83, yOffset: 20 },
  ],
  mammals: [
    { x: "2023", h: 1.47, r: 1.33, e: 0.82, yOffset: 20 },
    { x: "2024", h: 1.49, r: 1.4, e: 0.83, yOffset: 20 },
  ],
  herpetofauna: [
    { x: "2023", h: 1.59, r: 2.63, e: 0.72, yOffset: -20 },
    { x: "2024", h: 1.6, r: 1.55, e: 0.89, yOffset: -20 },
  ],
  dragonfly: [{ x: "2024", h: 0.81, r: 0.84, e: 0.59, yOffset: -20 }],
  butterfly: [{ x: "2024", h: 1.55, r: 0.51, e: 0.96, yOffset: -20 }],
};
