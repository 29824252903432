export const flora = [
  {
    No: 1,
    spesies: "Adenanthera pavonina",
    nama_lokal: "Saga",
    y2021: 0,
    y2022: 0,
    y2023: 3,
    y2024: 0,
  },
  {
    No: 2,
    spesies: "Aglaonema commutatum",
    nama_lokal: "Aglonema",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 8,
  },
  {
    No: 3,
    spesies: "Aglaonema nitidum ",
    nama_lokal: "Aglaonema",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 8,
  },
  {
    No: 4,
    spesies: "Aglaonema sp.",
    nama_lokal: "Sri Rejeki",
    y2021: 0,
    y2022: 0,
    y2023: 2,
    y2024: 8,
  },
  {
    No: 5,
    spesies: "Alocasia macrorrhizos",
    nama_lokal: "Taro",
    y2021: 8,
    y2022: 33,
    y2023: 3,
    y2024: 8,
  },
  {
    No: 6,
    spesies: "Alphonsea javanica",
    nama_lokal: "Setulang",
    y2021: 243,
    y2022: 4,
    y2023: 44,
    y2024: 44,
  },
  {
    No: 7,
    spesies: "Alstonia pneumatophore",
    nama_lokal: "Pulai rawa",
    y2021: 0,
    y2022: 3,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 8,
    spesies: "Anacardium occidentale",
    nama_lokal: "Jambu Mete",
    y2021: 0,
    y2022: 3,
    y2023: 4,
    y2024: 4,
  },
  {
    No: 9,
    spesies: "Antidesma bunius",
    nama_lokal: "Buni",
    y2021: 0,
    y2022: 3,
    y2023: 2,
    y2024: 2,
  },
  {
    No: 10,
    spesies: "Aquilaria malaccensis",
    nama_lokal: "Gaharu",
    y2021: 0,
    y2022: 0,
    y2023: 24,
    y2024: 25,
  },
  {
    No: 11,
    spesies: "Areca catechu",
    nama_lokal: "Pinang",
    y2021: 9,
    y2022: 5,
    y2023: 3,
    y2024: 4,
  },
  {
    No: 12,
    spesies: "Arenga pinnata",
    nama_lokal: "Aren",
    y2021: 0,
    y2022: 0,
    y2023: 28,
    y2024: 28,
  },
  {
    No: 13,
    spesies: "Artocarpus heterophyllus",
    nama_lokal: "Nangka",
    y2021: 0,
    y2022: 3,
    y2023: 8,
    y2024: 8,
  },
  {
    No: 14,
    spesies: "Artocarpus integer",
    nama_lokal: "Cempedak",
    y2021: 0,
    y2022: 40,
    y2023: 3,
    y2024: 4,
  },
  {
    No: 15,
    spesies: "Asplenium nidus",
    nama_lokal: "Paku Sarang Burung",
    y2021: 8,
    y2022: 5,
    y2023: 2,
    y2024: 1,
  },
  {
    No: 16,
    spesies: "Begonia sp.",
    nama_lokal: "Begonia",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 17,
    spesies: "Caladium bicolor",
    nama_lokal: "Keladi Merah",
    y2021: 15,
    y2022: 5,
    y2023: 2,
    y2024: 1,
  },
  {
    No: 18,
    spesies: "Caladium lindenii",
    nama_lokal: "Keladi",
    y2021: 0,
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 19,
    spesies: "Calamus rotang",
    nama_lokal: "Rotan",
    y2021: 0,
    y2022: 0,
    y2023: 10,
    y2024: 10,
  },
  {
    No: 20,
    spesies: "Calathea sp.",
    nama_lokal: "-",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 21,
    spesies: "Calophyllum dioscurii",
    nama_lokal: "Mentangor",
    y2021: 0,
    y2022: 0,
    y2023: 5,
    y2024: 1,
  },
  {
    No: 22,
    spesies: "Citrus sp.",
    nama_lokal: "Jeruk",
    y2021: 0,
    y2022: 0,
    y2023: 6,
    y2024: 0,
  },
  {
    No: 23,
    spesies: "Cocos nucifera",
    nama_lokal: "Kelapa",
    y2021: 0,
    y2022: 2,
    y2023: 20,
    y2024: 16,
  },
  {
    No: 24,
    spesies: "Cotylelobium melanoxylon",
    nama_lokal: "Resak",
    y2021: 0,
    y2022: 0,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 25,
    spesies: "Cratoxylon arborescens",
    nama_lokal: "Geronggang",
    y2021: 19,
    y2022: 68,
    y2023: 65,
    y2024: 66,
  },
  {
    No: 26,
    spesies: "Dendrodium Bigibbum",
    nama_lokal: "Anggrek",
    y2021: 20,
    y2022: 80,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 27,
    spesies: "Dillenia exima",
    nama_lokal: "Simpu",
    y2021: 0,
    y2022: 10,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 28,
    spesies: "Dimocarpus longan",
    nama_lokal: "Mata Kucing",
    y2021: 17,
    y2022: 5,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 29,
    spesies: "Dracaena marginata",
    nama_lokal: "Manggar",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 30,
    spesies: "Dracaena sp.",
    nama_lokal: "Drakaena",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 31,
    spesies: "Dyera costulata",
    nama_lokal: "Jelutung",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 32,
    spesies: "Elaeis guinensis",
    nama_lokal: "Sawit",
    y2021: 7,
    y2022: 4,
    y2023: 10,
    y2024: 9,
  },
  {
    No: 33,
    spesies: "Gomphandra capitulate",
    nama_lokal: "Mesiro",
    y2021: 0,
    y2022: 11,
    y2023: 90,
    y2024: 91,
  },
  {
    No: 34,
    spesies: "Heliconia psittacorum",
    nama_lokal: "Supit Udang",
    y2021: 0,
    y2022: 0,
    y2023: 6,
    y2024: 0,
  },
  {
    No: 35,
    spesies: "Hevea brasiliensis",
    nama_lokal: "Karet",
    y2021: 74,
    y2022: 15,
    y2023: 140,
    y2024: 145,
  },
  {
    No: 36,
    spesies: "Ilex cymosa",
    nama_lokal: "bengkulat",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 4,
  },
  {
    No: 37,
    spesies: "Macaranga pruinosa",
    nama_lokal: "Makaranga",
    y2021: 12,
    y2022: 15,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 38,
    spesies: "Macaranga triloba",
    nama_lokal: "Mahang",
    y2021: 0,
    y2022: 0,
    y2023: 10,
    y2024: 10,
  },
  {
    No: 39,
    spesies: "Mangifera indica",
    nama_lokal: "Mangga",
    y2021: 0,
    y2022: 19,
    y2023: 5,
    y2024: 5,
  },
  {
    No: 40,
    spesies: "Manilkara zapota",
    nama_lokal: "Sawo",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 2,
  },
  {
    No: 41,
    spesies: "Marantha sp.",
    nama_lokal: "Maranta",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 2,
  },
  {
    No: 42,
    spesies: "Maranta arundinacea",
    nama_lokal: "Garut",
    y2021: 0,
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 43,
    spesies: "Melaleuca leucadendra",
    nama_lokal: "Gelam",
    y2021: 35,
    y2022: 10,
    y2023: 47,
    y2024: 48,
  },
  {
    No: 44,
    spesies: "Melcope lunu-ancenda",
    nama_lokal: "Kayu bukam",
    y2021: 0,
    y2022: 11,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 45,
    spesies: "Melicote ptelefolia",
    nama_lokal: "Tenggek Burung",
    y2021: 0,
    y2022: 0,
    y2023: 10,
    y2024: 12,
  },
  {
    No: 46,
    spesies: "Nepenthes albomarginata",
    nama_lokal: "Kantong Beruk",
    y2021: 40,
    y2022: 17,
    y2023: 3,
    y2024: 4,
  },
  {
    No: 47,
    spesies: "Nepenthes ampularia",
    nama_lokal: "Kantung Semar",
    y2021: 120,
    y2022: 17,
    y2023: 88,
    y2024: 86,
  },
  {
    No: 48,
    spesies: "Nepenthes bicalcarata",
    nama_lokal: "Kantung Semar",
    y2021: 75,
    y2022: 3,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 49,
    spesies: "Nepenthes gracilis",
    nama_lokal: "Kantong Semar Gracilis",
    y2021: 0,
    y2022: 6,
    y2023: 6,
    y2024: 8,
  },
  {
    No: 50,
    spesies: "Nepenthes gracilis x ampullaria",
    nama_lokal: "Kantung Semar",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 2,
  },
  {
    No: 51,
    spesies: "Nepenthes mirabilis",
    nama_lokal: "Kantong semar mirabilis",
    y2021: 80,
    y2022: 28,
    y2023: 5,
    y2024: 6,
  },
  {
    No: 52,
    spesies: "Nepenthes rafflesiana",
    nama_lokal: "Kantong semar",
    y2021: 157,
    y2022: 20,
    y2023: 13,
    y2024: 14,
  },
  {
    No: 53,
    spesies: "Nepenthes spectabilis",
    nama_lokal: "Kantong Semar",
    y2021: 30,
    y2022: 10,
    y2023: 4,
    y2024: 6,
  },
  {
    No: 54,
    spesies: "Nepenthes sumantrana",
    nama_lokal: "Kantong semar sumatra",
    y2021: 30,
    y2022: 8,
    y2023: 3,
    y2024: 5,
  },
  {
    No: 55,
    spesies: "Nephelium lappaceum",
    nama_lokal: "Rambutan",
    y2021: 0,
    y2022: 0,
    y2023: 5,
    y2024: 0,
  },
  {
    No: 56,
    spesies: "Persea americana",
    nama_lokal: "Alpukat",
    y2021: 0,
    y2022: 7,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 57,
    spesies: "Philodendron burie-marxii",
    nama_lokal: "Brekele",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 3,
  },
  {
    No: 58,
    spesies: "Pometia pinnata",
    nama_lokal: "Matoa",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 59,
    spesies: "Rhodomtrus tomentosa",
    nama_lokal: "Kalimunting",
    y2021: 0,
    y2022: 11,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 60,
    spesies: "Sanseviera trifasciata",
    nama_lokal: "Lidah Mertua",
    y2021: 0,
    y2022: 0,
    y2023: 6,
    y2024: 6,
  },
  {
    No: 61,
    spesies: "Senna siamea",
    nama_lokal: "Johar",
    y2021: 0,
    y2022: 71,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 62,
    spesies: "Shorea balangeran",
    nama_lokal: "Blangeran",
    y2021: 0,
    y2022: 19,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 63,
    spesies: "Shorea leprosula",
    nama_lokal: "Meranti Tembaga",
    y2021: 150,
    y2022: 15,
    y2023: 10,
    y2024: 11,
  },
  {
    No: 64,
    spesies: "Shorea pauciflora",
    nama_lokal: "Meranti",
    y2021: 0,
    y2022: 0,
    y2023: 15,
    y2024: 16,
  },
  {
    No: 65,
    spesies: "Spatholobus littoralis",
    nama_lokal: "Bajaka/kait-kait",
    y2021: 0,
    y2022: 0,
    y2023: 3,
    y2024: 3,
  },
  {
    No: 66,
    spesies: "Stenochlaena palustris",
    nama_lokal: "Iding-iding",
    y2021: 0,
    y2022: 20,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 67,
    spesies: "Syzygium aqueum",
    nama_lokal: "Jambu Air",
    y2021: 38,
    y2022: 23,
    y2023: 22,
    y2024: 0,
  },
  {
    No: 68,
    spesies: "Syzygium cumini",
    nama_lokal: "kelad merah",
    y2021: 15,
    y2022: 25,
    y2023: 16,
    y2024: 18,
  },
  {
    No: 69,
    spesies: "Syzygium leptostemon",
    nama_lokal: "Kelad tikus",
    y2021: 80,
    y2022: 27,
    y2023: 52,
    y2024: 55,
  },
  {
    No: 70,
    spesies: "Syzygium malaccense",
    nama_lokal: "Jambu Bol/jamaika",
    y2021: 0,
    y2022: 0,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 71,
    spesies: "Syzygium oleana",
    nama_lokal: "Pucuk merah",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 3,
  },
  {
    No: 72,
    spesies: "Syzygium polyanthum",
    nama_lokal: "Salam",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 2,
  },
  {
    No: 73,
    spesies: "Syzygium zeylanicum",
    nama_lokal: "Nasi Nasi",
    y2021: 0,
    y2022: 28,
    y2023: 28,
    y2024: 28,
  },
  {
    No: 74,
    spesies: "Thaumatopylum xanadu",
    nama_lokal: "Daun pilo",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 3,
  },
  {
    No: 75,
    spesies: "Vitex pubescens",
    nama_lokal: "Leban",
    y2021: 42,
    y2022: 16,
    y2023: 18,
    y2024: 20,
  },
  {
    No: 76,
    spesies: "Vitis sp.",
    nama_lokal: "Anggur",
    y2021: 0,
    y2022: 0,
    y2023: 3,
    y2024: 0,
  },
];

export const birds = [
  {
    No: 1,
    spesies: "Acridotheres javanicus",
    nama_lokal: "Kerak kerbau",
    y2022: 0,
    y2023: 0,
    y2024: 4,
  },
  {
    No: 2,
    spesies: "Abroscopus superciliaris",
    nama_lokal: "Cikrak bambu",
    y2022: 0,
    y2023: 3,
    y2024: 0,
  },
  {
    No: 3,
    spesies: "Aegithina tiphia",
    nama_lokal: "Cipoh kacat",
    y2022: 3,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 4,
    spesies: "Anthreptes malacensis",
    nama_lokal: "Burung madu kelapa",
    y2022: 1,
    y2023: 4,
    y2024: 0,
  },
  {
    No: 5,
    spesies: "Centropus bengalensis",
    nama_lokal: "Bubut Alang-alang",
    y2022: 1,
    y2023: 1,
    y2024: 2,
  },
  {
    No: 6,
    spesies: "Centropus sinensis",
    nama_lokal: "Bubut besar",
    y2022: 2,
    y2023: 2,
    y2024: 1,
  },
  {
    No: 7,
    spesies: "Delichon dasypus",
    nama_lokal: "Layang-layang Rumah",
    y2022: 3,
    y2023: 4,
    y2024: 6,
  },
  {
    No: 8,
    spesies: "Dendrocopos moluccensis",
    nama_lokal: "Caladi tilik",
    y2022: 0,
    y2023: 5,
    y2024: 4,
  },
  {
    No: 9,
    spesies: "Dicrurus paradiseus",
    nama_lokal: "Srigunting batu",
    y2022: 1,
    y2023: 2,
    y2024: 2,
  },
  {
    No: 10,
    spesies: "Dinopium javanense",
    nama_lokal: "Pelatuk besi",
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 11,
    spesies: "Eurylaimus ochromalus",
    nama_lokal: "Sempur hujan darat",
    y2022: 0,
    y2023: 0,
    y2024: 2,
  },
  {
    No: 12,
    spesies: "Geopelia striata",
    nama_lokal: "Perkutut jawa",
    y2022: 4,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 13,
    spesies: "Hirundapus cochinchinensis",
    nama_lokal: "Kapinis jarum pantat putih",
    y2022: 4,
    y2023: 4,
    y2024: 4,
  },
  {
    No: 14,
    spesies: "Hirundo tahitica",
    nama_lokal: "Layang-layang Batu",
    y2022: 0,
    y2023: 4,
    y2024: 3,
  },
  {
    No: 15,
    spesies: "Hydrochous gigas",
    nama_lokal: "Walet Palem asia",
    y2022: 0,
    y2023: 4,
    y2024: 6,
  },
  {
    No: 16,
    spesies: "Hypothymis azurea ",
    nama_lokal: "Kehicap ranting",
    y2022: 5,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 17,
    spesies: "Malacocincla sepiaria",
    nama_lokal: "Pelanduk semak",
    y2022: 2,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 18,
    spesies: "Muscicapa latirostris ",
    nama_lokal: "Sikatan bubik",
    y2022: 2,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 19,
    spesies: "Orthotomus atrogularis ",
    nama_lokal: "Cinenen belukar",
    y2022: 3,
    y2023: 1,
    y2024: 2,
  },
  {
    No: 20,
    spesies: "Orthotomus ruficeps ",
    nama_lokal: "Cinenen kelabu",
    y2022: 3,
    y2023: 3,
    y2024: 4,
  },
  {
    No: 21,
    spesies: "Psilopogon rafflesii ",
    nama_lokal: "Takur tutut",
    y2022: 1,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 22,
    spesies: "Pachycephala grisola",
    nama_lokal: "Kancilan bakau",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 23,
    spesies: "Pycnonotus goiavier",
    nama_lokal: "Merbah cerucuk",
    y2022: 0,
    y2023: 0,
    y2024: 3,
  },
  {
    No: 24,
    spesies: "Pycnonotus plumosus ",
    nama_lokal: "Merbah belukar",
    y2022: 2,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 25,
    spesies: "Rhaphidura leucopygialis ",
    nama_lokal: "Kapinis jarum kecil",
    y2022: 0,
    y2023: 6,
    y2024: 6,
  },
  {
    No: 26,
    spesies: "Rhipidura javanica ",
    nama_lokal: "Kipasan belang",
    y2022: 1,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 27,
    spesies: "Spilopelia chinensis ",
    nama_lokal: "Tekukur biasa",
    y2022: 5,
    y2023: 4,
    y2024: 3,
  },
  {
    No: 28,
    spesies: "Todirhamphus chloris ",
    nama_lokal: "Cekakak sungai",
    y2022: 4,
    y2023: 2,
    y2024: 2,
  },
  {
    No: 29,
    spesies: "Treron vernans ",
    nama_lokal: "Punai gading",
    y2022: 1,
    y2023: 5,
    y2024: 4,
  },
];

export const mammals = [
  {
    No: 1,
    spesies: "Callosciurus notatus",
    nama_lokal: "Bajing kelapa",
    y2022: 4,
    y2023: 3,
    y2024: 7,
  },
  {
    No: 2,
    spesies: "Hipposideros sp.",
    nama_lokal: "Kelelawar",
    y2022: 0,
    y2023: 0,
    y2024: 3,
  },
  {
    No: 3,
    spesies: "Helarctos malayanus",
    nama_lokal: "Beruang madu",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 4,
    spesies: "Macaca fascicularis ",
    nama_lokal: "Monyet ekor panjang",
    y2022: 4,
    y2023: 9,
    y2024: 15,
  },
  {
    No: 5,
    spesies: "Macaca nemestrina",
    nama_lokal: "Beruk",
    y2022: 0,
    y2023: 0,
    y2024: 15,
  },
  {
    No: 6,
    spesies: "Mydaus javanensis ",
    nama_lokal: "Sigung",
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 7,
    spesies: "Presbytis melalophos",
    nama_lokal: "Simpai hitam sumatera",
    y2022: 5,
    y2023: 4,
    y2024: 10,
  },
  {
    No: 8,
    spesies: "Prionailurus bengalensis",
    nama_lokal: "Kucing hutan",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 9,
    spesies: "Sus scrofa",
    nama_lokal: "Babi hutan",
    y2022: 0,
    y2023: 0,
    y2024: 5,
  },
  {
    No: 10,
    spesies: "Trachypithecus cristatus ",
    nama_lokal: "Lutung kelabu",
    y2022: 6,
    y2023: 7,
    y2024: 15,
  },
  {
    No: 11,
    spesies: "Tupaia glis ",
    nama_lokal: "Tupai",
    y2022: 4,
    y2023: 0,
    y2024: 0,
  },
];

export const herpetofauna = [
  {
    No: 1,
    spesies: "Eutropis multifasciata",
    nama_lokal: "Bengkarung",
    y2022: 0,
    y2023: 0,
    y2024: 2,
  },
  {
    No: 2,
    spesies: "Hemidactylus frenatus",
    nama_lokal: "Cicak rumah",
    y2022: 1,
    y2023: 1,
    y2024: 3,
  },
  {
    No: 3,
    spesies: "Hylarana sp.",
    nama_lokal: "Kongkang",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 4,
    spesies: "Malayopython reticulatus ",
    nama_lokal: "Ular sanca batik",
    y2022: 0,
    y2023: 2,
    y2024: 1,
  },
  {
    No: 5,
    spesies: "Ophiopohagus hannah ",
    nama_lokal: "Ular kobra raja",
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 6,
    spesies: "Varanus salvator ",
    nama_lokal: "Biawak",
    y2022: 0,
    y2023: 2,
    y2024: 1,
  },
];

export const data_count = {
  flora: [
    { x: "2021", y: 1324 },
    { x: "2022", y: 725 },
    { x: "2023", y: 873 },
    { x: "2024", y: 889 },
  ],
  birds: [
    { x: "2022", y: 48 },
    { x: "2023", y: 62 },
    { x: "2024", y: 66 },
  ],
  mammals: [
    { x: "2022", y: 23 },
    { x: "2023", y: 24 },
    { x: "2024", y: 72 },
  ],
  herpetofauna: [
    { x: "2022", y: 1 },
    { x: "2023", y: 6 },
    { x: "2024", y: 8 },
  ],
};

export const h_index = {
  flora: [
    { x: "2019", y: 2.55, yOffset: -25 },
    { x: "2020", y: 2.69, yOffset: -25 },
    { x: "2021", y: 2.72, yOffset: -25 },
    { x: "2022", y: 3.1, yOffset: -25 },
    { x: "2023", y: 3.18, yOffset: -25 },
    { x: "2024", y: 3.19, yOffset: -25 },
  ],
  birds: [
    { x: "2021", y: 2.89, yOffset: 20 },
    { x: "2022", y: 2.8, yOffset: 20 },
    { x: "2023", y: 3.18, yOffset: 20 },
    { x: "2024", y: 2.93, yOffset: 20 },
  ],
  mammals: [
    { x: "2021", y: 0.69, yOffset: 20 },
    { x: "2022", y: 1.59, yOffset: 20 },
    { x: "2023", y: 1.42, yOffset: 20 },
    { x: "2024", y: 1.92, yOffset: 20 },
  ],
  herpetofauna: [
    { x: "2021", y: 0, yOffset: -20 },
    { x: "2022", y: 0.37, yOffset: -10 },
    { x: "2023", y: 1.33, yOffset: -20 },
    { x: "2024", y: 1.49, yOffset: -20 },
  ],
};

export const h_index_lines = {
  flora: [
    { x: "2019", y: 2.55, yOffset: -25 },
    { x: "2020", y: 2.69, yOffset: -25 },
    { x: "2021", y: 2.72, yOffset: -25 },
    { x: "2022", y: 3.1, yOffset: -25 },
    { x: "2023", y: 3.16, yOffset: -25 },
    { x: "2024", y: 3.17, yOffset: -25 },
  ],
  birds: [
    { x: "2021", y: 2.86, yOffset: -25 },
    { x: "2022", y: 2.87, yOffset: -25 },
    { x: "2023", y: 2.89, yOffset: -25 },
    { x: "2024", y: 2.92, yOffset: 20 },
  ],
  mammals: [
    { x: "2021", y: 0.8, yOffset: -25 },
    { x: "2022", y: 1.2, yOffset: -25 },
    { x: "2023", y: 1.7, yOffset: -25 },
    { x: "2024", y: 1.9, yOffset: 20 },
  ],
  herpetofauna: [
    { x: "2021", y: 0.0, yOffset: -25 },
    { x: "2022", y: 0.55, yOffset: -25 },
    { x: "2023", y: 1.2, yOffset: -25 },
    { x: "2024", y: 1.47, yOffset: -20 },
  ],
};

export const total_species = {
  flora: [
    { x: "2018", y: 13, yOffset: -25 },
    { x: "2019", y: 24, yOffset: -25 },
    { x: "2020", y: 26, yOffset: -25 },
    { x: "2021", y: 28, yOffset: -25 },
    { x: "2022", y: 41, yOffset: -25 },
    { x: "2023", y: 59, yOffset: -25 },
    { x: "2024", y: 76, yOffset: -25 },
  ],
  birds: [
    { x: "2022", y: 19, yOffset: -25 },
    { x: "2023", y: 21, yOffset: -25 },
  ],
};
