export const copywrite = {
  main_title: "Mangrove Desa Pangkalan Jambi",
  description: [
    //eslint-disable-next-line
    "Abrasi merupakan salah satu bencana yang sering dijumpai di wilayah Indonesia karena \
    sebagian besar wilayahnya adalah lautan. Selain karena faktor alam, abrasi yang sering \
    terjadi di Indonesia juga disebabkan oleh kerusakan hutan mangrove. Saat ini hutan mangrove \
    sedang mengalami tekanan yang berat dan mengalami kekurangan luasan di beberapa kawasan \
    pesisir terutama di wilayah yang berbatasan dengan laut lepas, sehingga akan membuat warga \
    yang ada di pesisir menderita bila terjadi air pasang.",
    //eslint-disable-next-line
    "Permasalahan abrasi ini juga terjadi di Riau, salah satunya di Desa Pangkalan Jambi, \
    Kabupaten Bengkalis. Lokasi desa yang berada di pesisir Riau dan kerusakan mangrove yang \
    cukup parah menyebabkan abrasi yang ada saat ini menjadi semakin parah. Saat ini daratan \
    yang ada di Desa Pangkalan Jambi telah mundur sejauh 115 meter.",
    //eslint-disable-next-line
    "Berbagai upaya telah dilakukan kelompok mangrove Harapan Bersama mulai tahun 2004 hingga \
    sekarang, namun kelompok sering mengalami kegagalan dalam proses penanaman mangrove sebagai \
    upaya pencegahan bencana abrasi karena kurangnya pengetahuan mengenai tatacara penanaman \
    mangrove dan besarnya arus yang menggerus daratan mereka. Mulai tahun 2017 PT Pertamina RU \
    II Sungai Pakning mulai memperkenalkan beberapa metode alat pemecah ombak yang digunakan \
    untuk meningkatkan keberhasilan penanaman mangrove dan pencegahan abrasi antara lain hybrid \
    engineering, lalu hybrid engineering yang telah di modifikasi dan terakhir ialah triangle \
    mangrove barrier.",
    //eslint-disable-next-line
    "Sebagai wujud kepedulian PT. Pertamina RU II Sungai Pakning terhadap perlindungan \
    biodiversity mangrove maka dibangunlah Mangrove Education Center (MEC) Pangkalan Jambi \
    sebagai tempat implementasi konservasi biodiversity ekosistem mangrove dan untuk edukasi \
    terhadap masyarakat.  Sebagai bagian dari pengelolaan Mangrove Education Center (MEC) maka \
    PT. Pertamina RU II Sungai Pakning Field secara rutin melakukan pemantauan/monev perkembangan \
    odiversity didalamnya.",
  ],
  flora_table_title: "Keanekaragaman Hayati Mangrove Pangkalan Jambi",
  bird_table_title: "Jumlah Spesies Burung Mangrove Pangkalan Jambi",
  mammals_table_title: "Jumlah Spesies Mamalia Mangrove Pangkalan Jambi",
  herpetofauna_table_title:
    "Jumlah Spesies Herpetofauna Mangrove Pangkalan Jambi",
  dragonfly_table_title: "Jumlah Spesies Capung Mangrove Pangkalan Jambi",
  butterfly_table_title: "Jumlah Spesies Kupu-kupu Mangrove Pangkalan Jambi",
};

export const flora = [
  {
    No: 1,
    spesies: "Acacia auriculiformis",
    nama_lokal: "Akasia",
    y2022: 0,
    y2023: 9450,
    y2024: 1575,
  },
  {
    No: 2,
    spesies: "Acacia mangium",
    nama_lokal: "Akasia",
    y2022: 0,
    y2023: 4725,
    y2024: 3150,
  },
  {
    No: 3,
    spesies: "Acrostichum aureum",
    nama_lokal: "Pakis bakau",
    y2022: 1890,
    y2023: 4725,
    y2024: 9450,
  },
  {
    No: 4,
    spesies: "Acrostichum speciosum",
    nama_lokal: "Pakis bakau",
    y2022: 5670,
    y2023: 4725,
    y2024: 6300,
  },
  {
    No: 5,
    spesies: "Ardisia elliptica",
    nama_lokal: "Lempeni",
    y2022: 1890,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 6,
    spesies: "Asystasia gangetica",
    nama_lokal: "Rumput israel",
    y2022: 0,
    y2023: 20475,
    y2024: 0,
  },
  {
    No: 7,
    spesies: "Avicennia alba",
    nama_lokal: "Api-api",
    y2022: 39690,
    y2023: 12600,
    y2024: 14175,
  },
  {
    No: 8,
    spesies: "Bruguiera cylindrica",
    nama_lokal: "Tancang putih",
    y2022: 10395,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 9,
    spesies: "Bruguiera gymnorrhiza",
    nama_lokal: "Tancang merah",
    y2022: 6615,
    y2023: 20475,
    y2024: 9450,
  },
  {
    No: 10,
    spesies: "Bruguiera sexangula",
    nama_lokal: "Busing",
    y2022: 8505,
    y2023: 1575,
    y2024: 0,
  },
  {
    No: 11,
    spesies: "Calophyllum sp.",
    nama_lokal: "Mentangor",
    y2022: 0,
    y2023: 0,
    y2024: 1575,
  },
  {
    No: 12,
    spesies: "Cerbera manghas",
    nama_lokal: "Bintaro",
    y2022: 2835,
    y2023: 6300,
    y2024: 0,
  },
  {
    No: 13,
    spesies: "Ceriops tagal",
    nama_lokal: "Tingi",
    y2022: 2835,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 14,
    spesies: "Cocos nucifera",
    nama_lokal: "Kelapa",
    y2022: 0,
    y2023: 12600,
    y2024: 9450,
  },
  {
    No: 15,
    spesies: "Derris trifoliata",
    nama_lokal: "Tuba",
    y2022: 8505,
    y2023: 7875,
    y2024: 3150,
  },
  {
    No: 16,
    spesies: "Excoecaria agallocha",
    nama_lokal: "Buta-buta",
    y2022: 2835,
    y2023: 22050,
    y2024: 12600,
  },
  {
    No: 17,
    spesies: "Fimbristylis ferruginea",
    nama_lokal: "Berkarat alang",
    y2022: 40635,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 18,
    spesies: "Ficus sp.",
    nama_lokal: "Beringin",
    y2022: 0,
    y2023: 3150,
    y2024: 0,
  },
  {
    No: 19,
    spesies: "Flagellaria indica",
    nama_lokal: "Rotan Tikus",
    y2022: 0,
    y2023: 0,
    y2024: 4725,
  },
  {
    No: 20,
    spesies: "Heritiera littoralis",
    nama_lokal: "Dungun",
    y2022: 6615,
    y2023: 7875,
    y2024: 0,
  },
  {
    No: 21,
    spesies: "Hibiscus tiliaceus",
    nama_lokal: "Waru",
    y2022: 7560,
    y2023: 11025,
    y2024: 4725,
  },
  {
    No: 22,
    spesies: "Lumnitzera littorea",
    nama_lokal: "Teruntum merah",
    y2022: 5670,
    y2023: 6300,
    y2024: 15750,
  },
  {
    No: 23,
    spesies: "Mangifera indica",
    nama_lokal: "Mangga",
    y2022: 0,
    y2023: 0,
    y2024: 1575,
  },
  {
    No: 24,
    spesies: "Nypa fruticans",
    nama_lokal: "Nipah",
    y2022: 25515,
    y2023: 4725,
    y2024: 17325,
  },
  {
    No: 25,
    spesies: "Phemphis acidula",
    nama_lokal: "Stigi",
    y2022: 0,
    y2023: 1575,
    y2024: 0,
  },
  {
    No: 26,
    spesies: "Pluchea indica",
    nama_lokal: "Beluntas",
    y2022: 0,
    y2023: 0,
    y2024: 11025,
  },
  {
    No: 27,
    spesies: "Pterocarpus indicus",
    nama_lokal: "Angsana",
    y2022: 0,
    y2023: 0,
    y2024: 3150,
  },
  {
    No: 28,
    spesies: "Rhizophora mucronata",
    nama_lokal: "Belukap",
    y2022: 15120,
    y2023: 3150,
    y2024: 0,
  },
  {
    No: 29,
    spesies: "Rhizophora stylosa",
    nama_lokal: "Bako kurap",
    y2022: 3780,
    y2023: 3150,
    y2024: 0,
  },
  {
    No: 30,
    spesies: "Rhizopora apiculata",
    nama_lokal: "Bakau kacang",
    y2022: 4725,
    y2023: 26775,
    y2024: 86625,
  },
  {
    No: 31,
    spesies: "Scyphiphora hydrophyllacea",
    nama_lokal: "-",
    y2022: 4725,
    y2023: 0,
    y2024: 11025,
  },
  {
    No: 32,
    spesies: "Sesuvium portulacastrum",
    nama_lokal: "Krokot",
    y2022: 0,
    y2023: 58275,
    y2024: 12600,
  },
  {
    No: 33,
    spesies: "Sonneratia alba",
    nama_lokal: "Prepat",
    y2022: 12285,
    y2023: 7875,
    y2024: 23625,
  },
  {
    No: 34,
    spesies: "Sonneratia caseolaris",
    nama_lokal: "Prepat Merah",
    y2022: 0,
    y2023: 0,
    y2024: 3150,
  },
  {
    No: 35,
    spesies: "Spinifex littoreus",
    nama_lokal: "Rumput lari-lari",
    y2022: 0,
    y2023: 6300,
    y2024: 0,
  },
  {
    No: 36,
    spesies: "Terminalia catappa",
    nama_lokal: "Ketapang",
    y2022: 0,
    y2023: 0,
    y2024: 1575,
  },
  {
    No: 37,
    spesies: "Xylocarpus granatum",
    nama_lokal: "Niri/nilih",
    y2022: 4725,
    y2023: 81900,
    y2024: 33075,
  },
];
export const birds = [
  {
    No: 1,
    spesies: "Acridotheres javanicus ",
    nama_lokal: "Jalak kerbau",
    y2022: 2,
    y2023: 8,
    y2024: 3,
  },
  {
    No: 2,
    spesies: "Acrocephalus bistrigiceps ",
    nama_lokal: "Kerak basi alis hitam",
    y2022: 1,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 3,
    spesies: "Aegithina tiphia",
    nama_lokal: "Cipoh kacat",
    y2022: 5,
    y2023: 0,
    y2024: 3,
  },
  {
    No: 4,
    spesies: "Aerodramus hirundinaceus ",
    nama_lokal: "Walet sarang putih",
    y2022: 4,
    y2023: 7,
    y2024: 6,
  },
  {
    No: 5,
    spesies: "Alcedo meninting ",
    nama_lokal: "Raja udang meninting",
    y2022: 0,
    y2023: 1,
    y2024: 2,
  },
  {
    No: 6,
    spesies: "Anthreptes malacensis",
    nama_lokal: "Burung-madu kelapa",
    y2022: 2,
    y2023: 3,
    y2024: 3,
  },
  {
    No: 7,
    spesies: "Anthreptes simplex ",
    nama_lokal: "Burung madu polos",
    y2022: 2,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 8,
    spesies: "Artamus leucorynchus ",
    nama_lokal: "Kekep babi",
    y2022: 0,
    y2023: 5,
    y2024: 0,
  },
  {
    No: 9,
    spesies: "Brachypteryx montana ",
    nama_lokal: "Cingcoang biru",
    y2022: 1,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 10,
    spesies: "Butorides striata ",
    nama_lokal: "Kokokan laut",
    y2022: 1,
    y2023: 4,
    y2024: 0,
  },
  {
    No: 11,
    spesies: "Cacomantis merulinus ",
    nama_lokal: "Wiwik kelabu",
    y2022: 1,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 12,
    spesies: "Cecropis striolata ",
    nama_lokal: "Layang-layang loreng",
    y2022: 3,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 13,
    spesies: "Centropus bengalensis",
    nama_lokal: "Bubut alang-alang",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 14,
    spesies: "Charadrius sp.",
    nama_lokal: "Cerek",
    y2022: 0,
    y2023: 3,
    y2024: 0,
  },
  {
    No: 15,
    spesies: "Collocalia esculenta ",
    nama_lokal: "Walet sapi",
    y2022: 9,
    y2023: 12,
    y2024: 5,
  },
  {
    No: 16,
    spesies: "Collocalia maxima ",
    nama_lokal: "Walet sarang hitam",
    y2022: 0,
    y2023: 6,
    y2024: 6,
  },
  {
    No: 17,
    spesies: "Delichon dasypus ",
    nama_lokal: "Layang-layang rumah",
    y2022: 9,
    y2023: 6,
    y2024: 7,
  },
  {
    No: 18,
    spesies: "Dendrocopos moluccensis ",
    nama_lokal: "Caladi tilik",
    y2022: 0,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 19,
    spesies: "Dicaeum trigonostigma ",
    nama_lokal: "Cabai bunga-api",
    y2022: 0,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 20,
    spesies: "Ficedula zanthopygia ",
    nama_lokal: "Sikatan emas",
    y2022: 6,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 21,
    spesies: "Geopelia striata ",
    nama_lokal: "Perkutut jawa",
    y2022: 3,
    y2023: 4,
    y2024: 3,
  },
  {
    No: 22,
    spesies: "Halcyon smyrnensis ",
    nama_lokal: "Cekakak belukar",
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 23,
    spesies: "Hemipus picatus ",
    nama_lokal: "Jinjing bukit",
    y2022: 4,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 24,
    spesies: "Hirundapus cochinchinensis ",
    nama_lokal: "Kapinis jarum pantat putih",
    y2022: 6,
    y2023: 2,
    y2024: 2,
  },
  {
    No: 25,
    spesies: "Hirundo rustica ",
    nama_lokal: "Layang-layang asia",
    y2022: 0,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 26,
    spesies: "Hirundo tahitica ",
    nama_lokal: "Layang-layang batu",
    y2022: 10,
    y2023: 3,
    y2024: 4,
  },
  {
    No: 27,
    spesies: "Lalage nigra",
    nama_lokal: "Kapasan kemiri",
    y2022: 0,
    y2023: 0,
    y2024: 2,
  },
  {
    No: 28,
    spesies: "Leptocoma calcostetha ",
    nama_lokal: "Burung-madu bakau",
    y2022: 5,
    y2023: 3,
    y2024: 1,
  },
  {
    No: 29,
    spesies: "Lonchura malacca ",
    nama_lokal: "Bondol rawa",
    y2022: 7,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 30,
    spesies: "Merops philippinus ",
    nama_lokal: "Kirik-kirik laut",
    y2022: 7,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 31,
    spesies: "Orthotomus ruficeps ",
    nama_lokal: "Cinenen kelabu",
    y2022: 12,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 32,
    spesies: "Passer montanus ",
    nama_lokal: "Gereja erasia",
    y2022: 0,
    y2023: 6,
    y2024: 4,
  },
  {
    No: 33,
    spesies: "Phaenicophaeus sumatranus",
    nama_lokal: "Kadalan saweh",
    y2022: 0,
    y2023: 0,
    y2024: 2,
  },
  {
    No: 34,
    spesies: "Picus vittatus",
    nama_lokal: "Pelatuk hijau",
    y2022: 0,
    y2023: 0,
    y2024: 2,
  },
  {
    No: 35,
    spesies: "Psilopogon henricii ",
    nama_lokal: "Takur topi emas",
    y2022: 2,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 36,
    spesies: "Psilopogon rafflesii ",
    nama_lokal: "Takur tutut",
    y2022: 1,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 37,
    spesies: "Pycnonotus aurigaster ",
    nama_lokal: "Kutilang",
    y2022: 4,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 38,
    spesies: "Pycnonotus brunneus ",
    nama_lokal: "Merbah mata-merah",
    y2022: 2,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 39,
    spesies: "Pycnonotus goiavier ",
    nama_lokal: "Merbah cerucuk",
    y2022: 5,
    y2023: 13,
    y2024: 8,
  },
  {
    No: 40,
    spesies: "Pycnonotus plumosus ",
    nama_lokal: "Merbah belukar",
    y2022: 4,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 41,
    spesies: "Rhaphidura leucopygialis ",
    nama_lokal: "Kapinis jarum kecil",
    y2022: 5,
    y2023: 3,
    y2024: 3,
  },
  {
    No: 42,
    spesies: "Spilopelia chinensis ",
    nama_lokal: "Tekukur biasa",
    y2022: 2,
    y2023: 3,
    y2024: 3,
  },
  {
    No: 43,
    spesies: "Todirhamphus chloris ",
    nama_lokal: "Cekakak sungai",
    y2022: 12,
    y2023: 6,
    y2024: 3,
  },
  {
    No: 44,
    spesies: "Gerygone sulphurea ",
    nama_lokal: "Remetuk laut",
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
];

export const mammals = [
  {
    No: 1,
    spesies: "Callosciurus notatus ",
    nama_lokal: "Bajing kelapa",
    y2022: 3,
    y2023: 3,
    y2024: 2,
  },
  {
    No: 2,
    spesies: "Macaca fascicularis",
    nama_lokal: "Monyet ekor panjang",
    y2022: 1,
    y2023: 9,
    y2024: 15,
  },
  {
    No: 3,
    spesies: "Sus scrofa ",
    nama_lokal: "Babi hutan",
    y2022: 2,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 4,
    spesies: "Presbytis melalophos",
    nama_lokal: "Simpai hitam sumatera",
    y2022: 5,
    y2023: 4,
    y2024: 8,
  },
  {
    No: 5,
    spesies: "Trachypithecus cristatus ",
    nama_lokal: "Lutung kelabu",
    y2022: 0,
    y2023: 6,
    y2024: 10,
  },
  {
    No: 6,
    spesies: "Tupaia minor",
    nama_lokal: "Tupai kecil",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
];

export const herpetofauna = [
  {
    No: 1,
    spesies: "Hemidactylus frenatus ",
    nama_lokal: "Cicak rumah asia",
    y2022: 3,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 2,
    spesies: "Hemidactylus platyurus",
    nama_lokal: "Cicak gula",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 3,
    spesies: "Ahaetulla prasina ",
    nama_lokal: "Ular pucuk daun",
    y2022: 0,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 4,
    spesies: "Boiga dendrophila ",
    nama_lokal: "Ular cincin emas",
    y2022: 0,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 5,
    spesies: "Laticauda colubrina",
    nama_lokal: "Erabu kuning/tiung belang",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 6,
    spesies: "Hypsiscopus plumbea",
    nama_lokal: "Ular-air Kelabu",
    y2022: 0,
    y2023: 0,
    y2024: 3,
  },
  {
    No: 7,
    spesies: "Cerberus rynchops ",
    nama_lokal: "Ular tambak",
    y2022: 1,
    y2023: 9,
    y2024: 3,
  },
  {
    No: 8,
    spesies: "Varanus salvator ",
    nama_lokal: "Biawak air",
    y2022: 1,
    y2023: 4,
    y2024: 2,
  },
  {
    No: 9,
    spesies: "Emoia atrocostata ",
    nama_lokal: "Kadal bakau",
    y2022: 1,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 10,
    spesies: "Crocodylidae",
    nama_lokal: "Buaya",
    y2022: 1,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 11,
    spesies: "Pseudoferania polylepis ",
    nama_lokal: "Ular air macaley's",
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
];

export const dragonfly = [
  {
    No: 1,
    spesies: "Neurothemis fluctuans",
    nama_lokal: "-",
    y2024: 2,
  },
  {
    No: 2,
    spesies: "Orthetrum sabina",
    nama_lokal: "-",
    y2024: 2,
  },
  {
    No: 3,
    spesies: "Raphismia bispina",
    nama_lokal: "-",
    y2024: 2,
  },
];

export const butterfly = [
  {
    No: 1,
    spesies: "Zizina otis",
    nama_lokal: "Kupu-kupu rumput Kecil",
    y2024: 2,
  },
  {
    No: 2,
    spesies: "Lyssa zampa",
    nama_lokal: "Ngengat ekor walet biasa",
    y2024: 1,
  },
  {
    No: 3,
    spesies: "Elymnias hypermnestra",
    nama_lokal: "Kupu-kupu palem biasa",
    y2024: 4,
  },
];

export const data_count = {
  flora: [
    { x: "2022", y: 223020 },
    { x: "2023", y: 349650 },
    { x: "2024", y: 300825 },
  ],
  birds: [
    { x: "2022", y: 137 },
    { x: "2023", y: 117 },
    { x: "2024", y: 86 },
  ],
  mammals: [
    { x: "2022", y: 11 },
    { x: "2023", y: 22 },
    { x: "2024", y: 35 },
  ],
  herpetofauna: [
    { x: "2022", y: 7 },
    { x: "2023", y: 17 },
    { x: "2024", y: 12 },
  ],
  dragonfly: [{ x: "2024", y: 6 }],
  butterfly: [{ x: "2024", y: 7 }],
};

export const h_index = {
  flora: [
    { x: "2021", y: 2.31, yOffset: -25 },
    { x: "2022", y: 2.64, yOffset: -25 },
    { x: "2023", y: 2.67, yOffset: -25 },
    { x: "2024", y: 2.69, yOffset: -25 },
  ],
  birds: [
    { x: "2021", y: 2.89, yOffset: 20 },
    { x: "2022", y: 2.8, yOffset: 20 },
    { x: "2023", y: 2.92, yOffset: 20 },
    { x: "2024", y: 2.69, yOffset: -25 },
  ],
  mammals: [
    { x: "2021", y: 0.69, yOffset: 20 },
    { x: "2022", y: 1.59, yOffset: 20 },
    { x: "2023", y: 1.42, yOffset: 20 },
  ],
  herpetofauna: [
    { x: "2021", y: 0, yOffset: -20 },
    { x: "2022", y: 0.37, yOffset: -10 },
    { x: "2023", y: 1.33, yOffset: -20 },
  ],
};

export const h_index_lines = {
  flora: [
    { x: "2019", y: 2.55, yOffset: -25 },
    { x: "2020", y: 2.69, yOffset: -25 },
    { x: "2021", y: 2.72, yOffset: -25 },
    { x: "2022", y: 3.1, yOffset: -25 },
    { x: "2023", y: 3.16, yOffset: -25 },
  ],
  birds: [
    { x: "2021", y: 2.86, yOffset: -25 },
    { x: "2022", y: 2.87, yOffset: -25 },
    { x: "2023", y: 2.89, yOffset: -25 },
  ],
  mammals: [
    { x: "2021", y: 0.8, yOffset: -25 },
    { x: "2022", y: 1.2, yOffset: -25 },
    { x: "2023", y: 1.7, yOffset: -25 },
  ],
  herpetofauna: [
    { x: "2021", y: 0.0, yOffset: -25 },
    { x: "2022", y: 0.55, yOffset: -25 },
    { x: "2023", y: 1.2, yOffset: -25 },
  ],
};

export const total_species = {
  flora: [
    { x: "2021", y: 381, yOffset: -25 },
    { x: "2022", y: 928, yOffset: -25 },
    { x: "2023", y: 1036, yOffset: -25 },
  ],
  birds: [
    { x: "2022", y: 22, yOffset: -25 },
    { x: "2023", y: 22, yOffset: -25 },
  ],
};
