export const copywrite = {
  main_title: "Penanaman Tanaman Kayu Khas Gambut Metode Poligowo",
  description: [
    //eslint-disable-next-line
    "Indonesia memiliki wilayah gambut yang luas yang tersebar dibeberapa provinsi. \
    Salah satu provinsi dengan ekosistem mangrove yang luas ialah di Provinsi Riau. \
    Ekosistem gambut seringkali dicap sebagai wilayah sumber bencana, saat kemarau tiba \
    sering terjadi kebakaran lahan dan hutan tetapi saat musim penghujan tiba sering kali \
    terjadi banjur. Sehingga keberadaan lahan gambut ini menjadi krusial apabila tidak \
    dikelola dengan baik.",
    //eslint-disable-next-line
    "Oleh karena itu PT Kilang Pertamina Internasional RU II Sungai Paning, sebagai salah \
    satu perusahaan yang berada di wilayah Sungai Pakning, Bengkalis, Provinsi Riau ikut \
    andil dalam upaya pelestarian keanekaragaman hayati terutama pada ekosistem gambut. \
    Salah satunya melalui Program Pelestarian Tanaman Khas Gambut Dengan Metode Penanaman \
    Bibit Nanas Dan Tanaman Kayu Secara Polikultur Jajar Legowo (Poligowo).",
    //eslint-disable-next-line
    "Kendala utama dalam upaya pelestarian gambut ini ialah seringnya terjadi benturan antar \
    kepentingan atara konservasi dengan ekonomi. Masyarakat jarang mau melakukan konservasi \
    karena merasa dampak konservasi tersebut hanya berdampak pada perbaikan lingkungan saja \
    tanpa memberikan manfaat ekonomi kepada mereka. Untuk itu PT Kilang Pertamina Internasional \
    RU II Sungai Paning membuat program Pelestarian Tanaman Khas Gambut Dengan Metode Penanaman \
    Bibit Nanas Dan Tanaman Kayu Secara Polikultur Jajar Legowo (Poligowo).",
    //eslint-disable-next-line
    "Program ini membuat upaya konservasi dapat dilakukan beriringan dengan kegiatan ekonomi \
    masyarakat. Sehingga dengan adanya manfaat ekonomi tersebut, kegiatan konservasi yang \
    dilakukan akan mampu juga memberikan dampak ekonomi kepada masyarakat, sehingga masyarakat \
    mau melakukan konservasi tersebut. Dengan adanya program ini juga sangat membantu penghijauan \
    terutama pada lahan – lahan gambut yang ,mulai kritis dan rawan terbakar. Dengan adanya \
    program ini juga mampu mencegah kebakaran karena lahan yang digunakan menjadi terawat dan \
    tidak berpotensi terhadap kebakaran. Program ini dilakukan di lahan seluas 2,5 ha yang berada \
    di dusun Kampung Jawa, Kelurahan Sungai Pakning, Kecamatan Bukit Batu, Kabupaten Bengkalis.",
  ],
  flora_table_title: "Keanekaragaman Hayati Program Poligowo",
  bird_table_title: "Jumlah spesies Burung di Poligowo",
  mammals_table_title: "Jumlah spesies Mamalia di Poligowo",
  herpetofauna_table_title: "Jumlah spesies Herpetofauna di Poligowo",
};

export const flora = [
  {
    No: 1,
    spesies: "Acasia auriculiformis",
    nama_lokal: "Akasia",
    y2021: 0,
    y2022: 6,
    y2023: 6,
    y2024: 0,
  },
  {
    No: 2,
    spesies: "Alphonsea javanica",
    nama_lokal: "Setulang",
    y2021: 0,
    y2022: 0,
    y2023: 12,
    y2024: 0,
  },
  {
    No: 3,
    spesies: "Alstonia pneumatophore",
    nama_lokal: "Pulai rawa",
    y2021: 32,
    y2022: 5,
    y2023: 5,
    y2024: 0,
  },
  {
    No: 4,
    spesies: "Anacardium occidentale",
    nama_lokal: "Jambu mete",
    y2021: 2,
    y2022: 3,
    y2023: 9,
    y2024: 4,
  },
  {
    No: 5,
    spesies: "Annona muricata",
    nama_lokal: "Sirsak",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 20,
  },
  {
    No: 6,
    spesies: "Aquilaria malacensis",
    nama_lokal: "Gaharu",
    y2021: 0,
    y2022: 45,
    y2023: 27,
    y2024: 0,
  },
  {
    No: 7,
    spesies: "Archidendron pauciflorum",
    nama_lokal: "Jengkol",
    y2021: 0,
    y2022: 0,
    y2023: 30,
    y2024: 0,
  },
  {
    No: 8,
    spesies: "Areca catechu",
    nama_lokal: "Pinang",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 4,
  },
  {
    No: 9,
    spesies: "Arenga pinnata",
    nama_lokal: "Aren",
    y2021: 20,
    y2022: 20,
    y2023: 0,
    y2024: 25,
  },
  {
    No: 10,
    spesies: "Artocarpus altilis",
    nama_lokal: "Sukun",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 11,
    spesies: "Artocarpus elasticus",
    nama_lokal: "Bendo",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 2,
  },
  {
    No: 12,
    spesies: "Artocarpus heterophyllus",
    nama_lokal: "Nangka",
    y2021: 98,
    y2022: 253,
    y2023: 257,
    y2024: 5,
  },
  {
    No: 13,
    spesies: "Calophyllum dioscurii",
    nama_lokal: "Mentangor",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 100,
  },
  {
    No: 14,
    spesies: "Chamaedaphne calyculata",
    nama_lokal: "Centingi",
    y2021: 0,
    y2022: 5,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 15,
    spesies: "Citrus sp.",
    nama_lokal: "Jeruk",
    y2021: 0,
    y2022: 0,
    y2023: 4,
    y2024: 4,
  },
  {
    No: 16,
    spesies: "Cocos nucifera",
    nama_lokal: "Kelapa",
    y2021: 3,
    y2022: 2,
    y2023: 2,
    y2024: 2,
  },
  {
    No: 17,
    spesies: "Coffea canephora",
    nama_lokal: "Kopi",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 10,
  },
  {
    No: 18,
    spesies: "Cratoxylon arborescens",
    nama_lokal: "Geronggang",
    y2021: 91,
    y2022: 1,
    y2023: 21,
    y2024: 0,
  },
  {
    No: 19,
    spesies: "Cymbopogon nardus",
    nama_lokal: "Serai wangi",
    y2021: 0,
    y2022: 15,
    y2023: 15,
    y2024: 0,
  },
  {
    No: 20,
    spesies: "Durio sp.",
    nama_lokal: "Durian",
    y2021: 4,
    y2022: 1,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 21,
    spesies: "Durio zibethinus",
    nama_lokal: "Durian",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 11,
  },
  {
    No: 22,
    spesies: "Elaeis guinensis",
    nama_lokal: "Sawit",
    y2021: 0,
    y2022: 5,
    y2023: 17,
    y2024: 174,
  },
  {
    No: 23,
    spesies: "Ficus sp.",
    nama_lokal: "Beringin",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 24,
    spesies: "Gomphandra capitulate",
    nama_lokal: "Malasiro/masiro",
    y2021: 45,
    y2022: 146,
    y2023: 44,
    y2024: 20,
  },
  {
    No: 25,
    spesies: "Hevea brasiliensis",
    nama_lokal: "Karet",
    y2021: 0,
    y2022: 205,
    y2023: 225,
    y2024: 115,
  },
  {
    No: 26,
    spesies: "Ilex cymosa",
    nama_lokal: "Bengkulat",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 27,
    spesies: "Laucaena leucocephala",
    nama_lokal: "Petai cina",
    y2021: 43,
    y2022: 10,
    y2023: 10,
    y2024: 0,
  },
  {
    No: 28,
    spesies: "Macaranga moetleyana",
    nama_lokal: "Mahang",
    y2021: 3,
    y2022: 3,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 29,
    spesies: "Macaranga sp.",
    nama_lokal: "Menarung",
    y2021: 0,
    y2022: 0,
    y2023: 4,
    y2024: 0,
  },
  {
    No: 30,
    spesies: "Malaleuca leucodendra",
    nama_lokal: "Gelam",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 5,
  },
  {
    No: 31,
    spesies: "Mangifera indica",
    nama_lokal: "Mangga",
    y2021: 0,
    y2022: 2,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 32,
    spesies: "Melaleuca leucadendra",
    nama_lokal: "Gelam",
    y2021: 0,
    y2022: 0,
    y2023: 14,
    y2024: 0,
  },
  {
    No: 33,
    spesies: "Melastoma sp.",
    nama_lokal: "Melastoma",
    y2021: 0,
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 34,
    spesies: "Melicope ptelefolia",
    nama_lokal: "Tengek burung",
    y2021: 11,
    y2022: 6,
    y2023: 243,
    y2024: 25,
  },
  {
    No: 35,
    spesies: "Moringa oleifera",
    nama_lokal: "Kelor",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 36,
    spesies: "Nepenthes ampularia x gracilis",
    nama_lokal: "Nepenthes",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 6,
  },
  {
    No: 37,
    spesies: "Nephelium lappaceum",
    nama_lokal: "Rambutan",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 38,
    spesies: "Parcia speciosa",
    nama_lokal: "Petai",
    y2021: 15,
    y2022: 43,
    y2023: 45,
    y2024: 9,
  },
  {
    No: 39,
    spesies: "Phoebe sp.",
    nama_lokal: "Medang",
    y2021: 6,
    y2022: 11,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 40,
    spesies: "Pometia pinnata",
    nama_lokal: "Matoa",
    y2021: 0,
    y2022: 0,
    y2023: 15,
    y2024: 75,
  },
  {
    No: 41,
    spesies: "Syzigium zeylanicum",
    nama_lokal: "Nasi – nasi",
    y2021: 0,
    y2022: 3,
    y2023: 3,
    y2024: 0,
  },
  {
    No: 42,
    spesies: "Syzygium aqueum",
    nama_lokal: "Jambu air",
    y2021: 0,
    y2022: 1,
    y2023: 1,
    y2024: 1,
  },
  {
    No: 43,
    spesies: "Syzygium leptostemon",
    nama_lokal: "Kelat tikus",
    y2021: 0,
    y2022: 0,
    y2023: 5,
    y2024: 5,
  },
  {
    No: 44,
    spesies: "Syzygium malaccense",
    nama_lokal: "Jambu bol",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 45,
    spesies: "Syzygium oleina",
    nama_lokal: "Pucuk merah",
    y2021: 0,
    y2022: 2,
    y2023: 5,
    y2024: 0,
  },
  {
    No: 46,
    spesies: "Syzygium sp.",
    nama_lokal: "Jambu-jambuan",
    y2021: 0,
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 47,
    spesies: "Syzygium zeylanicum",
    nama_lokal: "Nasi-nasi",
    y2021: 0,
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 48,
    spesies: "Uncaria elliptica",
    nama_lokal: "",
    y2021: 0,
    y2022: 129,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 49,
    spesies: "Vaccinium varingifolium",
    nama_lokal: "",
    y2021: 8,
    y2022: 1,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 50,
    spesies: "Vitex pubescens",
    nama_lokal: "Leban",
    y2021: 0,
    y2022: 5,
    y2023: 6,
    y2024: 0,
  },
];

export const birds = [
  {
    No: 1,
    spesies: "Acridotheres javanicus",
    nama_lokal: "Jalak kerbau",
    y2022: 3,
    y2023: 6,
    y2024: 9,
  },
  {
    No: 2,
    spesies: "Aerodramus hirundinaceus",
    nama_lokal: "Walet sarang putih",
    y2022: 4,
    y2023: 5,
    y2024: 16,
  },
  {
    No: 3,
    spesies: "Aerodramus maximus",
    nama_lokal: "Walet sarang hitam",
    y2022: 0,
    y2023: 0,
    y2024: 8,
  },
  {
    No: 4,
    spesies: "Alcedo coerulescens",
    nama_lokal: "Raja udang biru",
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 5,
    spesies: "Anthreptes malacensis",
    nama_lokal: "Burung madu kelapa",
    y2022: 13,
    y2023: 4,
    y2024: 8,
  },
  {
    No: 6,
    spesies: "Arachnothera crassirostris",
    nama_lokal: "Pijantung kampung",
    y2022: 4,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 7,
    spesies: "Ardeola bacchus",
    nama_lokal: "Blekok cina",
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 8,
    spesies: "Artamus leucoryn",
    nama_lokal: "Kekep babi",
    y2022: 7,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 9,
    spesies: "Cecropis striolata",
    nama_lokal: "Layang-layang Loreng",
    y2022: 10,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 10,
    spesies: "Centropus bengalensis",
    nama_lokal: "Bubut Alang-alang",
    y2022: 2,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 11,
    spesies: "Centropus sinensis",
    nama_lokal: "Bubut besar",
    y2022: 5,
    y2023: 1,
    y2024: 4,
  },
  {
    No: 12,
    spesies: "Collocalia esculenta",
    nama_lokal: "Walet sapi",
    y2022: 9,
    y2023: 4,
    y2024: 6,
  },
  {
    No: 13,
    spesies: "Collocalia linchi",
    nama_lokal: "Walet linchi",
    y2022: 0,
    y2023: 4,
    y2024: 9,
  },
  {
    No: 14,
    spesies: "Cypsiurus balasiensis",
    nama_lokal: "Walet palem asia",
    y2022: 0,
    y2023: 2,
    y2024: 4,
  },
  {
    No: 15,
    spesies: "Delichon dasypus",
    nama_lokal: "Layang-layang Rumah",
    y2022: 7,
    y2023: 0,
    y2024: 4,
  },
  {
    No: 16,
    spesies: "Dendrocopos moluccensis",
    nama_lokal: "Caladi tilik",
    y2022: 0,
    y2023: 3,
    y2024: 7,
  },
  {
    No: 17,
    spesies: "Dicaeum cruentatum",
    nama_lokal: "Cabai merah",
    y2022: 4,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 18,
    spesies: "Dicaeum trochileum",
    nama_lokal: "Cabai jawa",
    y2022: 5,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 19,
    spesies: "Elanus caeruleus",
    nama_lokal: "Elang tikus",
    y2022: 2,
    y2023: 2,
    y2024: 4,
  },
  {
    No: 20,
    spesies: "Gallus gallus",
    nama_lokal: "Ayam hutan merah",
    y2022: 0,
    y2023: 2,
    y2024: 2,
  },
  {
    No: 21,
    spesies: "Geopelia striata",
    nama_lokal: "Perkutut jawa",
    y2022: 8,
    y2023: 4,
    y2024: 6,
  },
  {
    No: 22,
    spesies: "Hirundapus giganteus",
    nama_lokal: "Kapinis jarum gedang",
    y2022: 0,
    y2023: 3,
    y2024: 4,
  },
  {
    No: 23,
    spesies: "Hirundo tahitica",
    nama_lokal: "Layang-layang batu",
    y2022: 0,
    y2023: 0,
    y2024: 14,
  },
  {
    No: 24,
    spesies: "Lalage nigra",
    nama_lokal: "Kapasan kemiri",
    y2022: 2,
    y2023: 2,
    y2024: 6,
  },
  {
    No: 25,
    spesies: "Lonchura atricapilla",
    nama_lokal: "Bondol coklat",
    y2022: 0,
    y2023: 0,
    y2024: 9,
  },
  {
    No: 26,
    spesies: "Lonchura maja",
    nama_lokal: "Bondol haji",
    y2022: 6,
    y2023: 4,
    y2024: 10,
  },
  {
    No: 27,
    spesies: "Micropternus brachyurus",
    nama_lokal: "Pelatuk kijang",
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 28,
    spesies: "Nectarinia jugularis",
    nama_lokal: "Burung madu sriganti",
    y2022: 7,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 29,
    spesies: "Pycnonotus aurigaster",
    nama_lokal: "Kutilang",
    y2022: 9,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 30,
    spesies: "Pycnonotus goiavier",
    nama_lokal: "Merbah cerucuk",
    y2022: 11,
    y2023: 7,
    y2024: 8,
  },
  {
    No: 31,
    spesies: "Rhaphidura leucopygialis",
    nama_lokal: "Kapinis jarum kecil",
    y2022: 10,
    y2023: 3,
    y2024: 0,
  },
  {
    No: 32,
    spesies: "Spilopelia chinensis",
    nama_lokal: "Tekukur biasa",
    y2022: 6,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 33,
    spesies: "Todirhamphus chloris",
    nama_lokal: "Cekakak sungai",
    y2022: 0,
    y2023: 2,
    y2024: 6,
  },
  {
    No: 34,
    spesies: "Treron vernans",
    nama_lokal: "Punai gading",
    y2022: 6,
    y2023: 3,
    y2024: 6,
  },
];

export const mammals = [
  {
    No: 1,
    spesies: "Collosciurus notatus",
    nama_lokal: "Bajing kelapa",
    y2022: 0,
    y2023: 2,
    y2024: 4,
  },
  {
    No: 2,
    spesies: "Cynopterus sphinx",
    nama_lokal: "Kelalawar/kalong",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 3,
    spesies: "Macaca fascicularis",
    nama_lokal: "Monyet ekor panjang",
    y2022: 1,
    y2023: 13,
    y2024: 24,
  },
  {
    No: 4,
    spesies: "Macaca nemestrina",
    nama_lokal: "Beruk mentawai",
    y2022: 1,
    y2023: 7,
    y2024: 10,
  },
  {
    No: 5,
    spesies: "Mydaus javanensis",
    nama_lokal: "Sigung",
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 6,
    spesies: "Prionailurus bengalensis",
    nama_lokal: "Kucing hutan",
    y2022: 0,
    y2023: 2,
    y2024: 0,
  },
  {
    No: 7,
    spesies: "Sus scrofa",
    nama_lokal: "Babi",
    y2022: 1,
    y2023: 2,
    y2024: 3,
  },
  {
    No: 8,
    spesies: "Trachypithecus cristatus",
    nama_lokal: "Lutung kelabu",
    y2022: 1,
    y2023: 5,
    y2024: 20,
  },
  {
    No: 9,
    spesies: "Tupaia glis",
    nama_lokal: "Tupai",
    y2022: 3,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 10,
    spesies: "Viverridae",
    nama_lokal: "Musang",
    y2022: 1,
    y2023: 1,
    y2024: 0,
  },
];

export const herpetofauna = [
  {
    No: 1,
    spesies: "Ahaetulla prasina",
    nama_lokal: "Ular pucuk",
    y2022: 1,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 2,
    spesies: "Dendrelaphis pictus",
    nama_lokal: "Ular tampar",
    y2022: 1,
    y2023: 0,
    y2024: 0,
  },
  {
    No: 3,
    spesies: "Draco sp.",
    nama_lokal: "Cecak terbang",
    y2022: 0,
    y2023: 0,
    y2024: 6,
  },
  {
    No: 4,
    spesies: "Duttaphrynus melanostictus",
    nama_lokal: "Kodok buduk",
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 5,
    spesies: "Eutropis multifasciata",
    nama_lokal: "Kadal kebun",
    y2022: 0,
    y2023: 2,
    y2024: 8,
  },
  {
    No: 6,
    spesies: "Fejervarya sp.",
    nama_lokal: "Kodok sawah",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 7,
    spesies: "Hemidactylus frenatus",
    nama_lokal: "Cecak pohon",
    y2022: 0,
    y2023: 0,
    y2024: 1,
  },
  {
    No: 8,
    spesies: "Homalopsis buccata",
    nama_lokal: "Ular air belang",
    y2022: 0,
    y2023: 0,
    y2024: 5,
  },
  {
    No: 9,
    spesies: "Hylarana erythrea",
    nama_lokal: "Kongkang gading",
    y2022: 0,
    y2023: 0,
    y2024: 5,
  },
  {
    No: 10,
    spesies: "Ophiophagus hannah",
    nama_lokal: "Kobra raja",
    y2022: 2,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 11,
    spesies: "Python reticulatus",
    nama_lokal: "Ular sanca batik",
    y2022: 0,
    y2023: 1,
    y2024: 0,
  },
  {
    No: 12,
    spesies: "Varanus salvator",
    nama_lokal: "Biawak air",
    y2022: 1,
    y2023: 2,
    y2024: 2,
  },
];

export const data_count = {
  flora: [
    { x: "2021", y: 381 },
    { x: "2022", y: 928 },
    { x: "2023", y: 1036 },
    { x: "2024", y: 631 },
  ],
  birds: [
    { x: "2022", y: 140 },
    { x: "2023", y: 68 },
    { x: "2024", y: 150 },
  ],
  mammals: [
    { x: "2022", y: 8 },
    { x: "2023", y: 33 },
    { x: "2024", y: 62 },
  ],
  herpetofauna: [
    { x: "2022", y: 5 },
    { x: "2023", y: 8 },
    { x: "2024", y: 28 },
  ],
};

export const h_index = {
  flora: [
    { x: "2021", y: 1.26, yOffset: -25 },
    { x: "2022", y: 2.1, yOffset: -25 },
    { x: "2023", y: 2.13, yOffset: -25 },
    { x: "2024", y: 2.21, yOffset: -25 },
  ],
  birds: [
    { x: "2021", y: 2.89, yOffset: 20 },
    { x: "2022", y: 2.8, yOffset: 20 },
    { x: "2023", y: 2.92, yOffset: 20 },
  ],
  mammals: [
    { x: "2021", y: 0.69, yOffset: 20 },
    { x: "2022", y: 1.59, yOffset: 20 },
    { x: "2023", y: 1.42, yOffset: 20 },
  ],
  herpetofauna: [
    { x: "2021", y: 0, yOffset: -20 },
    { x: "2022", y: 0.37, yOffset: -10 },
    { x: "2023", y: 1.33, yOffset: -20 },
  ],
};

export const h_index_lines = {
  flora: [
    { x: "2019", y: 2.55, yOffset: -25 },
    { x: "2020", y: 2.69, yOffset: -25 },
    { x: "2021", y: 2.72, yOffset: -25 },
    { x: "2022", y: 3.1, yOffset: -25 },
    { x: "2023", y: 3.16, yOffset: -25 },
  ],
  birds: [
    { x: "2021", y: 2.86, yOffset: -25 },
    { x: "2022", y: 2.87, yOffset: -25 },
    { x: "2023", y: 2.89, yOffset: -25 },
  ],
  mammals: [
    { x: "2021", y: 0.8, yOffset: -25 },
    { x: "2022", y: 1.2, yOffset: -25 },
    { x: "2023", y: 1.7, yOffset: -25 },
  ],
  herpetofauna: [
    { x: "2021", y: 0.0, yOffset: -25 },
    { x: "2022", y: 0.55, yOffset: -25 },
    { x: "2023", y: 1.2, yOffset: -25 },
  ],
};

export const total_species = {
  flora: [
    { x: "2021", y: 381, yOffset: -25 },
    { x: "2022", y: 928, yOffset: -25 },
    { x: "2023", y: 1036, yOffset: -25 },
    { x: "2024", y: 631, yOffset: -25 },
  ],
  birds: [
    { x: "2022", y: 140, yOffset: -25 },
    { x: "2023", y: 68, yOffset: -25 },
    { x: "2024", y: 150, yOffset: -25 },
  ],
  mammals: [
    { x: "2022", y: 8, yOffset: -25 },
    { x: "2023", y: 32, yOffset: -25 },
    { x: "2024", y: 62, yOffset: -25 },
  ],
  herpetofauna: [
    { x: "2022", y: 5, yOffset: -25 },
    { x: "2023", y: 8, yOffset: -25 },
    { x: "2024", y: 28, yOffset: -25 },
  ],
};
